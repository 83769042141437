/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { GraphQLError } from 'graphql';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import WineCard from '../../../../components/WineCard';
import { Headers } from '../../../../components/Table/types';
import PlaceholderImage from '../../../../assets/images/stub-wine-image.png';
import { SelectedRowsProvider } from '../../../../context/selectedRowsContext';
import TablePagination from '../../../../components/TablePagination';
import ErrorPlaceholder from '../../../../components/ErrorPlaceholder';
import Toolbar, { ViewType } from '../../../../components/Table/Toolbar';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { selectSortOptions, selectTableData, TableData } from './selectors';
import { ActiveMarketsFacets, ActiveMarketSortObject, Modal } from './types';
import CalculationToolTip from '../../../../components/CalculationToolTip';
import Table from '../../../../components/Table';
import { useActiveMarketAssets } from '../../../../hooks/useActiveMarketAssets';
import getImageUrl from '../../../../utils/getImageUrl';
import WineCardSkeleton from '../../../../components/WineCardSkeleton';
import { uuid } from '../../../../utils/uuid';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import OrderModal from '../../../../components/OrderModal';
import { Mode } from '../../../../components/OrderModal/types';
import BuyNowModal from '../../../../components/BuyNowModal';
import useCardPaymentCallback from '../../../../hooks/useCardPaymentCallback';
import CardPaymentErrorModal from '../../../../components/CardPaymentCallbackModal/errorContent';
import CardPaymentSuccessModal from '../../../../components/CardPaymentCallbackModal/successContent';
import { useQueryParameters } from '../../../../hooks';
import { selectErrorMessage } from '../../../../graphql/selectors/selectErrorMessage';
import Typography from '../../../../components/Typography';
import useTableControls from '../../../../hooks/useTableControls';
import { logError } from '../../../../utils/logger';
import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';
import { TableRefactorFlagSet } from '../../../../types/FeatureFlags';
import ActiveMarketsTable from './Table';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2.2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

const ACTIVE_MARKET_PAGE_SIZE = 50;

function ActiveMarkets({ defaultView, searchTextIn = '' }: { defaultView?: ViewType; searchTextIn?: string }) {
  const { activeMarketWineNameFilter, clickableTradeTiles13816 } = useFlags();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParameters();
  const selectedModal = queryParams.get('modal');
  const selectedAssetId = queryParams.get('assetId');
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [searchtext, setSearchText] = React.useState<string | undefined>(activeMarketWineNameFilter ? '' : undefined);
  const [from, setFrom] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [viewType, setViewType] = React.useState<ViewType>(defaultView || 'card');
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal, showTopUpBuySuccessSummaryModal } =
    useCardPaymentCallback();
  const { handleSortUpdated, sortFacet, sortDirection } = useTableControls<ActiveMarketSortObject>({
    defaultFacet: 'LatestOffers',
  });
  const {
    error,
    loading,
    results: assets,
    total,
  } = useActiveMarketAssets({
    from,
    pageSize: ACTIVE_MARKET_PAGE_SIZE,
    sortBy: sortFacet,
    sortDirection,
    wineName: searchtext,
  });
  const headers: Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets> = {
    wineName: { title: t('market:table.wineName'), id: 'name' },
    region: { title: t('common:region'), id: 'region' },
    vintage: { title: t('common:vintage'), id: 'vintage' },
    unitSize: { title: t('common:unitSize'), id: 'unitSize' },
    marketValue: {
      title: t('product:marketValue'),
      id: 'marketValue',
      extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
    },
    highestBid: {
      title: t('product:trading.highestBid'),
      id: 'highestBid',
    },
    lowestOffer: {
      title: t('product:trading.lowestOffer'),
      id: 'lowestOffer',
    },
    spread: {
      title: t('product:trading.spread'),
      id: 'spread',
    },
  };

  React.useEffect(() => {
    if (error) {
      logError({
        error: new Error('Failed to load active market assets'),
        originalError: error,
        filename: 'src/views/Discover/components/ActiveMarkets',
        additionalInfo: {
          searchtext: searchtext ?? '',
          sortDirection,
          sortFacet,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setSearchText(searchTextIn);
  }, [searchTextIn]);

  const sortOptions = React.useMemo(() => selectSortOptions('asc', sortFacet, t), [sortFacet, t]);

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);
    if (isUndefined(opt)) {
      return;
    }

    handleSortUpdated(opt.key, opt.direction);
  }

  const handleChangePage = async (_: React.MouseEvent<HTMLButtonElement> | null, _pageNumber: number) => {
    setPageNumber(_pageNumber);
    setFrom(_pageNumber * ACTIVE_MARKET_PAGE_SIZE);
  };

  const handleWineNameFilterTextChanged: undefined | ((text: string) => void) = activeMarketWineNameFilter
    ? (text) => setSearchText(text)
    : undefined;

  function handleAddToWatchlist() {
    enqueueSnackbar(t('product:watchList.addedMessage'), {
      variant: 'info',
    });
  }

  function handleRemoveFromWatchlist() {
    enqueueSnackbar(t('product:watchList.deletedMessage'), {
      variant: 'info',
    });
  }

  function handleWatchlistError(errors: readonly GraphQLError[]) {
    enqueueSnackbar(selectErrorMessage(errors), {
      variant: 'error',
    });
  }

  function handleOpenBuyNowModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}&assetId=${rowId}`);
  }

  function handleOpenBidModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}&assetId=${rowId}`);
  }

  function handleOpenOfferModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Offer}&assetId=${rowId}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  function closeCardPaymentErrorModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  function closeCardPaymentSuccessModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  const tableData = selectTableData({
    rawData: assets,
    handleAddToWatchlist,
    handleOpenBidModalClicked,
    handleOpenBuyNowModalClicked,
    handleOpenOfferModalClicked,
    handleRemoveFromWatchlist,
    handleWatchlistError,
    clickableTradeTiles13816,
  });

  if (!loading && assets.length === 0 && !activeMarketWineNameFilter && !searchtext?.length) {
    return null;
  }

  const TableComponent = tableRefactor?.activeMarkets ? (
    <ActiveMarketsTable
      assets={assets}
      loading={loading}
      error={error ? t('common:somethingWentWrong') : undefined}
      onAddToWatchlist={handleAddToWatchlist}
      onRemoveFromWatchlist={handleRemoveFromWatchlist}
      onWatchlistError={handleWatchlistError}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onBidClicked={handleOpenBidModalClicked}
      onOfferClicked={handleOpenOfferModalClicked}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      error={error ? t('common:somethingWentWrong') : null}
      loading={loading}
      emptyTableText={t('discover:activeMarkets.noResultsNoFilter')}
    />
  );

  return (
    <div>
      <Typography customVariant="h4">{t('discover:activeMarkets.title')}</Typography>
      <Toolbar
        resultCount={total}
        viewType={viewType}
        onChangeViewType={setViewType}
        hideChangeViewType={lessThanMd}
        onSortChanged={handleToolbarSortChanged}
        sortOptions={sortOptions}
        searchText={undefined}
        onSearchTextChanged={handleWineNameFilterTextChanged}
        searchFieldPlaceholder={t('discover:activeMarkets.searchFieldPlaceholder')}
        preferSortMenuButton
      />
      {viewType === 'table' && !lessThanMd ? (
        TableComponent
      ) : (
        <div className={classes.cards}>
          {loading &&
            !assets.length &&
            !error &&
            new Array(ACTIVE_MARKET_PAGE_SIZE)
              .fill(0)
              .map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
          {error && <ErrorPlaceholder error={t('common:somethingWentWrong')} />}
          {!assets.length && activeMarketWineNameFilter && !searchtext?.length && !error && !loading ? (
            <EmptyPlaceholder label={t('discover:activeMarkets.noResultsNoFilter')} />
          ) : (
            assets.map((a) => {
              const tradeTileVariant = clickableTradeTiles13816 ? 'basic' : 'date';

              return (
                <WineCard
                  key={a.assetId}
                  className={classes.card}
                  assetId={a.assetId}
                  cardId={a.assetId.toString()}
                  imageUrl={a.imageFileName ? getImageUrl(a.imageFileName, { height: 200 }) : PlaceholderImage}
                  region={a.region}
                  score={null}
                  vintage={a.vintage}
                  wineName={a.wineName}
                  spread={a.spread}
                  lowestOffer={a.lowestOffer.price}
                  highestBid={a.highestBid.price}
                  marketValue={a.marketValue}
                  tradeTileVariant={tradeTileVariant}
                  offerCreatedDate={
                    clickableTradeTiles13816 || isNullOrUndefined(a.lowestOffer.createdDate)
                      ? undefined
                      : new Date(a.lowestOffer.createdDate).toLocaleDateString()
                  }
                  bidCreatedDate={
                    clickableTradeTiles13816 || isNullOrUndefined(a.highestBid.createdDate)
                      ? undefined
                      : new Date(a.highestBid.createdDate).toLocaleDateString()
                  }
                  actions={{
                    watchlist: {
                      onAdd: handleAddToWatchlist,
                      onRemove: handleRemoveFromWatchlist,
                      onError: handleWatchlistError,
                    },
                    trade: {
                      assetId: a.assetId,
                      condensed: true,
                      onBidClicked: handleOpenBidModalClicked,
                      onBuyClicked: handleOpenBuyNowModalClicked,
                      onOfferClicked: handleOpenOfferModalClicked,
                    },
                  }}
                  unitCount={a.unitCount}
                  unitSize={a.unitSize}
                />
              );
            })
          )}
        </div>
      )}
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={ACTIVE_MARKET_PAGE_SIZE}
        page={pageNumber}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[ACTIVE_MARKET_PAGE_SIZE]}
      />
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.Bid && (
        <OrderModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.Offer && (
        <OrderModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.BuyNow && (
        <BuyNowModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.BuyNow}
          onClose={handleCloseModal}
        />
      )}

      {/* after a success topup on buy modal, url string should include #buy_topup hash and assetId query param thus 
      dynamically render BuyNowModal component so user can continue the buy now flow */}
      {selectedAssetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(selectedAssetId)} open onClose={handleCloseModal} />
      )}

      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}
export default function ActiveMarketsWithContext({
  defaultView,
  searchTextIn = '',
}: {
  defaultView?: ViewType;
  searchTextIn?: string;
}): JSX.Element {
  return (
    // TODO: have the market comparison page expose this number so we can never get out of sync.
    // maxRows = 8 because that is the total columns on the market comparison page is 8
    <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
      <ActiveMarkets defaultView={defaultView} searchTextIn={searchTextIn} />
    </SelectedRowsProvider>
  );
}
